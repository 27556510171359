import { Component, OnInit } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AnimationService } from '../../../../../shared/services/animation/animation.service'
import { DeviceBillingService } from '../../services/device-billing.service'
import { DeviceEventHandlerService } from '../../services/device-event-handler.service'
import { DeviceService } from '../../services/device.service'

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
})
export class DeviceManagementComponent implements OnInit {
  constructor(
    public proficloud: ProficloudService,
    public ui: UiService,
    public animation: AnimationService,
    public deviceEventHandler: DeviceEventHandlerService,
    public deviceBilling: DeviceBillingService,
    public deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.proficloud.organisationsListed$.subscribe(() => {
      this.deviceEventHandler.initiateDeviceEventListener()
    })
  }

  highlightClipboardAction(element: HTMLDivElement) {
    this.animation.animateEasing('upDown', 500).subscribe(({ easingProgressDecimal }) => {
      element.style.opacity = easingProgressDecimal + ''
      const scale = 1 + easingProgressDecimal / 1.5
      element.style.transform = `scale(${scale})`
    })
  }
}
