@if (navItem | navItemVisible) {
  <div class="nav-item" (clickOutside)="childrenVisible = false">
    <flex-col
      class="nav-item_container"
      [class.nav-item_container--minimized]="minimized"
      [@minimizeNavigationItem]="(navigation.navigationToggled$ | async) ? 'minimized' : 'maximized'"
      (mouseenter)="navItem.hover = !ui.isTouchScreen()"
      (mouseleave)="navItem.hover = false"
      (click)="openRoute()"
      [id]="navItem.id"
      [attr.data-analytics]="navItem.analyticsTag"
      cdkOverlayOrigin
      #childPanelTrigger="cdkOverlayOrigin">
      <div class="nav-item_content" [class.nav-item_content--minimized]="minimized">
        <div class="nav-item_icon-container">
          <app-icon
            [name]="navItem.icon"
            class="nav-item_icon"
            [color]="navItem.id === ui.routeServiceId || navItem.hover ? 'var(--navigation-hover)' : 'var(--jet-black)'"></app-icon>
        </div>
        @if (!minimized) {
          <div class="nav-item_inner-content">
            <!-- left: name -->
            <div
              class="nav-item_title-container"
              [class.nav-item_title-container--minimized]="navigation.navigationAnimationRunning$ | async"
              [class.nav-item_title-container--active]="navItem.id === ui.routeServiceId || navItem.hover">
              @if (navItem.link) {
                <a>
                  {{ navItem.name | translate }}
                </a>
              }
              @if (!navItem.link) {
                <span>
                  {{ navItem.name | translate }}
                </span>
              }
            </div>
            @if (navItem | navItemHasVisibleChild) {
              <app-icon
                class="nav-item_extend-icon"
                [name]="navItem.id === ui.routeServiceId ? 'chevronDown' : 'chevronRight'"
                [color]="navItem.id === ui.routeServiceId || navItem.hover ? 'var(--navigation-hover)' : 'var(--jet-black)'"></app-icon>
            }
          </div>
        }
      </div>
      <!--- right: bar -->
      <div class="nav-item_indicator" [class.nav-item_indicator--active]="navItem.id === ui.routeServiceId || navItem.hover"></div>
    </flex-col>
    @if (navItem.children && navItem.showChildrenAt === ui.routeServiceId && !minimized) {
      @for (child of navItem.children; track child; let i = $index; let isFirst = $first) {
        @if (child | navItemChildVisible) {
          <flex-row
            style="font-size: 16px"
            class="child-item_container"
            [class.child-item_controller]="isFirst"
            [routerLink]="[child.routerLink]"
            [id]="child.id"
            [attr.data-analytics]="child.analyticsTag">
            <div class="{{ window.location.pathname === child.routerLink ? 'item_activated' : '' }} child-item">
              {{ child.name }}
            </div>
            <!-- right: bar -->
            <div class="styles-navItem-container-right">
              <div
                class="styles-navItem-container-right-bar"
                [style.background]="window.location.pathname === child.routerLink ? 'var(--navigation-hover)' : 'white'"></div>
            </div>
          </flex-row>
        }
      }
    }
    @if (navItem.children && minimized && (navigation.navigationAnimationRunning$ | async) === false) {
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="childPanelTrigger"
        [cdkConnectedOverlayOpen]="childrenVisible"
        [cdkConnectedOverlayPositions]="positionPairs">
        <div class="child-item_container--minimized">
          <div class="child-item_container-title--minimized child-item_container-title--minimized-first">
            {{ navItem.name | translate }}
          </div>
          @for (child of navItem.children; track child; let last = $last) {
            @if (child | navItemChildVisible) {
              <flex-row
                class="child-item--minimized"
                [class.child-item--minimized-active]="window.location.pathname === child.routerLink"
                [class.child-item--minimized-last]="last"
                [routerLink]="[child.routerLink]"
                [id]="child.id"
                [attr.data-analytics]="child.analyticsTag">
                <div class="{{ window.location.pathname === child.routerLink ? 'item_activated' : '' }}">
                  {{ child.name }}
                </div>
              </flex-row>
            }
          }
        </div>
      </ng-template>
    }
  </div>
}
