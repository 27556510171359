import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill'
import { Subject, map } from 'rxjs'
import { Device, DeviceInfo } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { ApplicationImage, ApplicationImageResponse, ApplicationUpdate, SSEUpdateManagementMessage, UpdateStage, UpdateStatus } from './application.interfaces'

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  currentApplication: ApplicationImage

  currentUpdateId: string

  currentUpdate: ApplicationUpdate

  currentUpdateStages: UpdateStage[]
  deviceId: string

  applicationUpdateDialog$: Subject<Device | false> = new Subject()

  applicationUploaded$: Subject<boolean> = new Subject()

  applicationUpdateStarted$: Subject<boolean> = new Subject()

  applicationUpdateCompleted$: Subject<{ success: boolean; message: string }> = new Subject()

  private messageEventSource: EventSourcePolyfill
  appItems: any
  timestampStage: string

  constructor(
    public proficloud: ProficloudService,
    private httpBase: HttpBaseService
  ) {}

  loadUpdateStages() {
    this.currentUpdateStages = [
      {
        headline: 'Step 1 - Checking Compatability:',
        status: this.currentUpdate.compatibilityStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 2 - Downloading Image:',
        status: this.currentUpdate.downloadStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 3 - Verifying Downloaded Image:',
        status: this.currentUpdate.verifyStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 4 - Installing:',
        status: this.currentUpdate.installStatus,
        timestamp: this.timestampStage,
      },
    ]
  }

  //////////////////////////// REST CALLS /////////////////////////////////////////////

  uploadApplicationFile(file: File) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/applications`
    const formData = new FormData()
    formData.append('file', new Blob([file]), file.name)
    formData.append('fileName', file.name)

    let headers: HttpHeaders = new HttpHeaders(this.proficloud.getAuthHeader())

    this.proficloud.http
      .post<ApplicationImageResponse>(url, formData, {
        headers,
      })
      .subscribe({
        next: (r) => {
          this.currentApplication = r.data
          this.applicationUploaded$.next(true)
        },
        error: (error) => {
          this.applicationUploaded$.next(false)
        },
      })
  }

  installApplication(id: string, device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/application`
    const payload = {
      context: 'hello!',
      data: {
        id,
      },
    }

    this.proficloud.http.put<null>(url, payload, { observe: 'response' }).subscribe({
      next: (applicationResponse) => {
        // Note: This is a break from the usual pattern - we are getting the next URL from the headers of the response.
        // This is because the backend follows the HATEOS principle.
        // There was a very long conversation about this with the backend guys but basically the conclusion is that it's staying like that.
        // In the words of Peter O'hanrahanrahan: "I don't like it, but I'll have to go along with it!"
        const location = applicationResponse.headers.get('location')
        if (!location) {
          console.warn('Location not found in response headers')
          return
        }
        const updateId = location.split('/')[location.split('/').length - 1]
        this.currentUpdateId = updateId
        this.applicationUpdateStarted$.next(true)

        this.proficloud.http.get(location).subscribe((res: ApplicationUpdate) => {
          this.currentUpdate = res
          this.deviceId = res.data.device.id
          this.initApplicationUpdateStatus()
          this.loadUpdateStages()
        })
      },
      error: (error) => {
        this.applicationUpdateStarted$.next(false)
      },
    })
  }

  loadAppUpdateHistory(device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/updates?updateType=application`
    return this.proficloud.http.get<any>(url).pipe(
      map((response: { data: { items: any } }) => {
        this.appItems = response.data.items
        return this.appItems
      })
    )
  }

  getUpdateStatusById(updateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${updateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.statuses
      })
    )
  }
  getAppUpdateName(appUpdateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/applications/${appUpdateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.manifest.identification.name
      })
    )
  }
  getActiveUpdate(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/updates?active=true&updateType=application`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        return response.data.totalItems
      })
    )
  }
  initApplicationUpdateStatus() {
    this.currentUpdate.compatibilityStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentUpdate.downloadStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentUpdate.verifyStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentUpdate.installStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
  }

  public openStatusSSE() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/sse/`
    const token = localStorage.getItem('access_token')
    try {
      this.messageEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      this.messageEventSource.addEventListener('update', (evt: MessageEvent) => {
        const event: SSEUpdateManagementMessage = JSON.parse(evt.data)
        this.timestampStage = event.received_at

        // Some terrible hacks to keep using the old pattern because I don't have time to refactor it properly:
        // not started: -1
        // requested: 0
        // pending: 1 (running)
        // ok: 2
        // fail: 3
        let statusToUpdate: UpdateStatus | undefined

        if (event.status.includes('abort')) {
          return
        }

        // Stage
        if (event.status.includes('compat')) {
          statusToUpdate = this.currentUpdate?.compatibilityStatus
        }

        if (event.status.includes('download')) {
          statusToUpdate = this.currentUpdate?.downloadStatus
        }

        if (event.status.includes('verify')) {
          statusToUpdate = this.currentUpdate?.verifyStatus
        }

        if (event.status.includes('install')) {
          statusToUpdate = this.currentUpdate?.installStatus

          if (event.status.includes('ok')) {
            this.applicationUpdateCompleted$.next({ success: true, message: 'Application update successful.' })
          }
        }

        // Status
        if (!statusToUpdate) {
          console.warn('stage not recognised')
          return
        }

        if (event.status.includes('requested')) {
          statusToUpdate.statusCode = 0
          statusToUpdate.message = event.message || 'Requested'
        }

        if (event.status.includes('pending')) {
          statusToUpdate.statusCode = 1
          statusToUpdate.message = event.message || 'Pending'
        }

        if (event.status.includes('ok')) {
          statusToUpdate.statusCode = 2
          statusToUpdate.message = event.message || 'Success'
        }

        if (event.status.includes('fail')) {
          statusToUpdate.statusCode = 3
          statusToUpdate.message = event.message || 'Failed'
        }

        // Set progress if we have one
        if (event.progress) {
          statusToUpdate.progress = event.progress
        }

        this.loadUpdateStages()
      })
    } catch (e) {
      console.error(e)
    }
  }

  public abortCurrentUpdate() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${this.currentUpdateId}/abort`
    let headers: HttpHeaders = new HttpHeaders(this.proficloud.getAuthHeader())
    this.proficloud.http
      .put<ApplicationImageResponse>(url, {
        headers,
      })
      .subscribe((res) => {
        this.applicationUpdateCompleted$.next({ success: false, message: 'Aborted by user' })
      })
  }
}
