import { HttpErrorResponse } from '@angular/common/http'
// amgular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { sharedAnimations } from 'src/app/modules/shared/shared.animations'
import userIconColorMapJSON from 'src/assets/user-management/user-icon-color-map-array.json'
// app
import { environment } from '@environments/environment'
import { EnvironmentInterface } from '@environments/environment.interface'
import { AuthorisationService } from '@services/authorisation.service'
import { BillingService } from '@services/billing/billing.service'
import { IamService } from '@services/iam.service'
import { NotificationService } from '@services/notification.service'
import { PCOverlayConfig, UserDetailsResponse } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { Subscription } from 'rxjs'
import { first, take } from 'rxjs/operators'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { AppService } from '../../../../app.service'
import { WhitelabelService } from '../../../shared/services/white-labels/whitelabel.service'
import { DeviceService } from '../../modules/device-management/services/device.service'
import { NavigationService } from '../navigation/navigation.service'
import { IIconColor } from './interfaces/i-icon-color'
import { profileLogoHoverAnimation } from './proficloud-shell.animation'

@Component({
  selector: 'app-proficloud-shell',
  templateUrl: './proficloud-shell.component.html',
  styleUrls: ['./proficloud-shell.component.scss', '../../../shared/styles/shared-styles.scss'],
  animations: [
    sharedAnimations.widthSlide,
    sharedAnimations.heightSlide,
    sharedAnimations.scaleIn,
    sharedAnimations.scaleOut,
    sharedAnimations.widthSlideTrueFalse,
    profileLogoHoverAnimation,
  ],
})
export class ProficloudShellComponent implements OnInit, OnDestroy {
  @ViewChild('content')
  contentContainer: ElementRef

  env: EnvironmentInterface = environment

  menuVisible = false

  settingItem = {
    name: 'Settings',
    icon: 'gear',
  }

  // Create new organization
  createOrgDialog: PCOverlayConfig = {
    title: 'Your Organization',
  }

  showCreateOrg = false

  createOrgNameControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(256), Validators.pattern('^[-_.@, A-Za-z0-9]+$')])

  createOrganisationForm = new UntypedFormGroup({ name: this.createOrgNameControl })

  firstNameColor: IIconColor = { id: 'A', color: '#FFFFFF' }

  lastNameColor: IIconColor = { id: 'A', color: '#FFFFFF' }

  orgListedSubscription: Subscription = new Subscription()

  profileLogoHovered: boolean = false

  constructor(
    public deviceService: DeviceService,
    public notificationService: NotificationService,
    public app: AppService,
    public authorization: AuthorisationService,
    public billing: BillingService,
    public proficloud: ProficloudService,
    public iam: IamService,
    public translate: TranslateService,
    public ui: UiService,
    private statusOverlay: PcStatusOverlayService,
    public navigation: NavigationService,
    public wlService: WhitelabelService
  ) {
    // Waits for userdetails to be set and refreshes language settings
    this.proficloud.userDataFetched$.subscribe((userDetails: UserDetailsResponse) => {
      // check if attributes exist at all. we had an issue were this logic assumed attributes is initialized
      if (userDetails?.data?.attributes) {
        // attributes exist, check for the locale setting if present set the selected locale as default
        if (userDetails?.data?.attributes?.locale) {
          translate.use(userDetails.data.attributes.locale[0]).subscribe({
            next: () => {
              const locale = userDetails.data.attributes?.locale
              if (locale) {
                console.log('using language: ', locale[0])
              } else {
                console.log('locale missing')
              }
            },
            error: (err) => {
              console.log('failed to use language: ', err)
            },
          })
        } else {
          userDetails.data.attributes.locale = [translate.getDefaultLang()]
          // save the user profile like we do in user settings
        }
      }

      // Get profile avatar colors
      this.generateProfileAvatar()
    })

    // gets organization information in order to present them in the top navigation
    if (!this.proficloud.organisations) {
      this.iam.listOrganisations$().subscribe({
        next: () => {
          // check that the current organization inside the token is in the organization list at al
          // if the organization the user was part or got deleted while the user was offline
          if (!this.proficloud.currentOrganisation || !this.iam.isInsideOrganisations(this.proficloud.currentOrganisation.organizationId)) {
            console.log('The users activeOrg field did not exist in the list of organizations or was not set. Switching to first org in list.')
            this.iam.switchOrganisation(this.proficloud.organisations[0])
          }

          this.iam.refreshUserDetails()

          // listen to org listed events for further calls
          this.orgListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
            this.iam.refreshUserDetails()
          })
        },
      })
    } else {
      this.iam.refreshUserDetails()
    }
  }

  ngOnInit() {
    // New experimental notifications backend - this should only be live on dev for now
    if (this.app.environment.frontend !== 'production') {
      // Do only once for initializing, the organization switch happens inside the message service
      this.proficloud.organisationsListed$.pipe(first()).subscribe(() => {
        if (!this.proficloud.keycloakData.userDetails) {
          const sub = this.proficloud.userDataFetched$.subscribe(() => {
            this.notificationService.setupMessagingBackend()
            sub.unsubscribe()
          })
        } else {
          this.notificationService.setupMessagingBackend()
        }
      })
    }
  }

  createOrganization() {
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.createOrganizationBusy)

    const name = this.createOrganisationForm.get('name')?.value
    if (!name) {
      console.warn('missing organisation name')
      return
    }

    this.iam.createIamOrganisation(name).subscribe({
      next: (orgaDetails) => {
        this.showCreateOrg = false
        // refresh organizations
        this.iam
          .listOrganisations$()
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.statusOverlay.showStatus(this.proficloud.statusOverlays.createOrganizationSuccess)

              if (orgaDetails.data.organizationId) {
                const desiredOrg = this.proficloud.organisations.find((org) => org.organizationId === orgaDetails.data.organizationId)

                if (desiredOrg) {
                  this.iam.switchOrganisation(desiredOrg)
                }
              }

              setTimeout(() => {
                this.statusOverlay.resetStatus()
              }, 2000)
            },
          })
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.createOrganizationError)
      },
    })
  }
  generateProfileAvatar() {
    // Get capitalized first characters of user name
    // Fallback first two characters of email
    let firstLetterFirstName = this.proficloud.keycloakData.userDetails?.data.firstName.charAt(0).toUpperCase()
    let firstLetterLastName = this.proficloud.keycloakData.userDetails?.data.lastName.charAt(0).toUpperCase()

    if (!firstLetterFirstName || !firstLetterLastName) {
      firstLetterFirstName = this.proficloud.keycloakData.userDetails?.data.email.charAt(0).toUpperCase() || 'unknown'
      firstLetterLastName = this.proficloud.keycloakData.userDetails?.data.email.charAt(1).toUpperCase() || 'unknown'
    }

    // Get color list and assign colors based on name
    // Fallback: color based on email
    const colorMap: string[] = userIconColorMapJSON.colors
    this.firstNameColor = {
      id: firstLetterFirstName,
      color: colorMap[firstLetterFirstName.charCodeAt(0) % colorMap.length],
    }
    this.lastNameColor = {
      id: firstLetterLastName,
      color: colorMap[firstLetterLastName.charCodeAt(0) % colorMap.length],
    }
  }

  ngOnDestroy(): void {
    this.orgListedSubscription.unsubscribe()
  }
}
