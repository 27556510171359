<pc-overlay [config]="billing.billingAddressDialog" (closeClick)="closeClick.emit()">
  <ng-container body>
    @for (field of billing.billingFormCompanyFields; track field) {
      @if (field.type === 'text') {
        <pc-input-textfield
          [control]="field.control"
          [placeholder]="field.placeholder"
          variant="fit-width"
          [errorHint]="field.control.valid ? '' : field?.errorHint">
        </pc-input-textfield>
      }
      @if (field.type === 'select') {
        <pc-input-select [form]="billing.billingAddressForm" [config]="field"></pc-input-select>
      }
    }
    @if (!billing.hideCustomerNumberFieldControl.value) {
      <p>{{ 'USR_SETTINGS.PXC_CUSTOMER_NUMBER_INFO' | translate }}</p>
    }
    <!-- With this the user can opt out of giving the customer number -->
    <mat-checkbox (change)="toggleCustomerNumber()" [formControl]="billing.hideCustomerNumberFieldControl">{{
      'USR_SETTINGS.NO_PXC_CUSTOMER_NUMBER' | translate
    }}</mat-checkbox>
    <div style="margin: 0px; width: 100%">{{ 'USR_SETTINGS.MANDATORY' | translate }}</div>
  </ng-container>

  <flex-row-end buttons style="justify-content: flex-end">
    <pc-button
      [disabled]="!billing.billingAddressForm?.valid"
      size="small"
      [text]="billing.billingAccount ? ('USR_SETTINGS.UPDATE_BILLING_ACCOUNT' | translate) : ('USR_SETTINGS.CREATE_BILLING_ACCOUNT' | translate)"
      (btnClick)="submitClick.emit()"></pc-button>
  </flex-row-end>
</pc-overlay>
