import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { UiService } from '@services/ui/ui.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { DeviceBillingService } from '../../../modules/device-management/services/device-billing.service'
import { BillingService } from '../../../services/billing/billing.service'
import { Device, DeviceInfo, SubscriptionResponse } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'

@Component({
  selector: 'app-subscription-devices-modal',
  // templateUrl: './subscription-devices-modal.component.html',
  templateUrl: './subscription-devices-modal.component.html',
  styleUrls: ['./subscription-devices-modal.component.scss'],
})
export class SubscriptionDevicesModalComponent implements OnInit {
  @Input() config: {
    subscription: SubscriptionResponse
    candidateDevices: DeviceInfo[]
    devicesUsed: string[]
  }

  subscriptionEndpointIds: string[]

  devicesToAdd = 0

  constructor(
    public billing: BillingService,
    public deviceBilling: DeviceBillingService,
    public proficloud: ProficloudService,
    public translate: TranslateService,
    public ui: UiService,
    private statusOverlay: PcStatusOverlayService
  ) {}

  ngOnInit(): void {
    // create an array of used id's to work with
    const ids = this.config.devicesUsed || []
    this.subscriptionEndpointIds = JSON.parse(JSON.stringify(ids))
    this.filteredDevices$.next(this.config.candidateDevices)
  }

  // we want to know the state of each candidate device. added (here, elsewhere, nowhere)
  deviceAssignedState(device: Device, subscription: SubscriptionResponse): 'here' | 'elsewhere' | 'nowhere' {
    // check if it's part of this subscription
    if ((this.config.devicesUsed || []).includes(device.endpointId)) {
      return 'here'
    }

    // get all all other subscrition
    let allotherSubscriptionDevices: string[][] = []
    if (this.config.subscription.smartServiceId === 'ia') {
      allotherSubscriptionDevices = this.billing.impulseAnalyticsSubscriptions.filter((sub) => sub.id !== subscription.id).map((sub) => sub.devicesUsed)
    } else if (this.config.subscription.smartServiceId === 'dmsAddonBasic') {
      allotherSubscriptionDevices = this.deviceBilling.subscriptions.filter((sub) => sub.id !== subscription.id).map((sub) => sub.endpointIds)
    }

    const flatDevices = ([] as string[]).concat(...allotherSubscriptionDevices)
    if (flatDevices.includes(device.endpointId)) {
      return 'elsewhere'
    }

    return 'nowhere'
  }

  assignDevices() {
    const devices = this.subscriptionEndpointIds

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesBusy)

    let billingRequest$: Observable<Object> | undefined
    if (this.config.subscription.smartServiceId === 'ia') {
      billingRequest$ = this.billing.setImpulseAssignedDevices(this.config.subscription.id, devices)
    } else if (this.config.subscription.smartServiceId === 'dmsAddonBasic') {
      billingRequest$ = this.deviceBilling.setDevicesToSubscription(this.config.subscription.id, devices)
    }
    billingRequest$?.subscribe({
      next: (response) => {
        switch (this.config.subscription.smartServiceId) {
          case 'dmsAddonBasic':
            this.deviceBilling.fetchDeviceBillingSubscriptions()
            break
          case 'ia':
            this.billing.fetchSubscriptions()
            break
        }

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)

        this.devicesToAdd = 0

        console.log('assign' + this.config.subscription.smartServiceId + ' devices success', response)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesError)
        console.log('assign' + this.config.subscription.smartServiceId + ' device error', error)
      },
    })
  }

  onCheckChange(checked: boolean, endpointId: string) {
    if (checked) {
      this.subscriptionEndpointIds.push(endpointId)
      this.devicesToAdd++
    } else {
      this.subscriptionEndpointIds = this.subscriptionEndpointIds.filter((id) => id !== endpointId)
      this.devicesToAdd--
    }
  }

  queryTsdDevices(query: string) {
    const allDevices = this.config.candidateDevices
    return allDevices.filter((device) => {
      return (
        device.metadata.deviceName.toLocaleLowerCase().includes(query.toLowerCase()) ||
        device.metadata.uuid?.toLowerCase().includes(query.toLowerCase()) ||
        device.availableMetrics?.some((m) => m.toLowerCase().includes(query.toLowerCase()))
      )
    })
  }

  filteredDevices$ = new BehaviorSubject<DeviceInfo[]>([])
  filterInput$ = new Subject()
  filterTap$ = this.filterInput$
    .pipe(
      tap((query: string) => {
        const filtered = this.queryTsdDevices(query)
        this.filteredDevices$.next(filtered)
      })
    )
    .subscribe()
}
