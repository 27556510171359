<div class="container">
  <router-outlet></router-outlet>
</div>

<!-- Virtual UUID overlay -->
@if (deviceService.showVirtualUuid$ | async; as uuid) {
  <pc-overlay
    (closeClick)="deviceService.showVirtualUuid$.next(false)"
    [config]="{ title: 'Virtual Device Created' }"
    data-analytics="device-management-virtual-uuid-overlay">
    <div body data-analytics="device-management-virtual-uuid-created">
      {{ 'DEVICE.VIRTUAL_DEVICE_CREATE_SUCCESS' | translate }}<br /><br />
      <div class="semibold">{{ 'DEVICE.UUID' | translate }}</div>
      <flex-row>
        <div
          style="margin-top: 10px; display: inline-block; font-family: monospace; padding: 5px 10px; border: 1px solid #ddd; background: #eee"
          data-analytics="device-management-virtual-uuid-id">
          {{ uuid }}
        </div>
        <div #clipboardCopyConfirmation style="margin-left: 20px; opacity: 0" data-analytics="device-management-virtual-uuid-device-copied">
          {{ 'DEVICE.COPIED' | translate }}
        </div>
      </flex-row>
    </div>
    <flex-row-end buttons data-analytics="device-management-virtual-uuid-button-section">
      <pc-button
        variant="secondary"
        size="small"
        [text]="'DEVICE.COPY_UUID_TO_CLIPBOARD' | translate"
        (btnClick)="proficloud.writeToClipboard(uuid); highlightClipboardAction(clipboardCopyConfirmation)"
        data-analytics="device-management-virtual-uuid-copy-uuid-to-clipboard-button"></pc-button>
    </flex-row-end>
  </pc-overlay>
}
