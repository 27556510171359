// angular
// maps module
import { AgmCoreModule } from '@agm/core'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
// angular forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
// material
import { MatCardModule } from '@angular/material/card'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSliderModule } from '@angular/material/slider'
import { TranslateModule } from '@ngx-translate/core'
import { SharedModule } from '../shared/shared.module'
import { AccountManagementComponent } from './components/account-management/account-management.component'
import { AuthenticationScreenComponent } from './components/authentication-screen/authentication-screen.component'
import { ExpandableOrganisationComponent } from './components/expandable-organisation/expandable-organisation.component'
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component'
import { NotificationListComponent } from './components/notification-list/notification-list.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
// app
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay'
import { MatBadgeModule } from '@angular/material/badge'
import { MatListModule } from '@angular/material/list'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { MultiStatusOverlayComponent } from './components/multi-status-overlay/multi-status-overlay.component'
import { NavigationComponent } from './components/navigation/navigation.component'
import { NotificationBellComponent } from './components/notification-bell/notification-bell.component'
import { NotificationListItemComponent } from './components/notification-list-item/notification-list-item.component'
import { ProficloudShellComponent } from './components/proficloud-shell/proficloud-shell.component'
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component'
import { PublicHeaderComponent } from './components/public-header/public-header.component'
import { RootComponent } from './components/root/root.component'
import { BillingAddressOverlayComponent } from './components/services/billing-address-overlay/billing-address-overlay.component'
import { CartCalculationComponent } from './components/services/cart-calculation/cart-calculation.component'
import { SelectSubscriptionModalComponent } from './components/services/select-subscription-modal/select-subscription-modal.component'
import { ServiceDetailComponent } from './components/services/service-detail/service-detail.component'
import { ServiceListComponent } from './components/services/service-list/service-list.component'
import { ServicesRootComponent } from './components/services/services-root/services-root.component'
import { SubscriptionDevicesModalComponent } from './components/services/subscription-devices-modal/subscription-devices-modal.component'
import { SubscriptionMetricsModalComponent } from './components/services/subscription-metrics-modal/subscription-metrics-modal.component'
import { SubscriptionInformationComponent } from './components/subscription-information/subscription-information.component'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { NavItemChildVisiblePipe } from './pipes/nav-item-child-visible.pipe'
import { NavItemHasVisibleChildPipe } from './pipes/nav-item-has-visible-child.pipe'
import { NavItemVisiblePipe } from './pipes/nav-item-visible.pipe'
import { ProficloudRoutingModule } from './proficloud-routing.module'

export const proficloudModuleDeclarations = [
  AccountManagementComponent,
  AuthenticationScreenComponent,
  BillingAddressOverlayComponent,
  CartCalculationComponent,
  ExpandableOrganisationComponent,
  NavigationItemComponent,
  NotificationListComponent,
  PageNotFoundComponent,
  ProficloudShellComponent,
  PublicHeaderComponent,
  RootComponent,
  ServiceDetailComponent,
  ServiceListComponent,
  ServicesRootComponent,
  MultiStatusOverlayComponent,
  SubscriptionDevicesModalComponent,
  SubscriptionMetricsModalComponent,
  UserSettingsComponent,
]

export const proficloudModuleImports = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  ProficloudRoutingModule,
  SharedModule,
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyBQz-aJiOW1cYTmmQnk0zi-Iij2jQzKy2U',
    libraries: ['places'],
  }),
  MatFormFieldModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatSliderModule,
  MatButtonModule,
  MatMenuModule,
  MatCardModule,
  MatTabsModule,
  MatExpansionModule,
  MatIconModule,
  MatDatepickerModule,
  MatInputModule,
  MatTableModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatBadgeModule,
  MatStepperModule,
  OverlayModule,
]

@NgModule({
  declarations: [
    proficloudModuleDeclarations,
    NotificationBellComponent,
    NotificationListItemComponent,
    MultiStatusOverlayComponent,
    NavigationComponent,
    SubscriptionInformationComponent,
    ProfileMenuComponent,
    SelectSubscriptionModalComponent,
    NavItemVisiblePipe,
    NavItemChildVisiblePipe,
    NavItemHasVisibleChildPipe,
  ],
  imports: [proficloudModuleImports, TranslateModule.forChild(), NgxDropzoneModule, CdkConnectedOverlay, MatListModule, MatRippleModule],
  exports: [
    PublicHeaderComponent,
    MultiStatusOverlayComponent,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatSliderModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatStepperModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } },
  ],
})
export class ProficloudModule {}
