<!-- new, upgrade tsd -->
@if (tsdUpgrade) {
  <div style="background: #eee; margin-bottom: 20px; padding: 20px; width: 100%; box-sizing: border-box">
    @if (billing.billingDetails && billing.billingDetails.upgradePreview) {
      <flex-row-start-center style="width: 100%; margin-bottom: 20px">
        <img [src]="billing.upgradeService.fields.icon.fields.file.url" color="#222" style="width: 80px; height: 80px; margin-right: 20px" />
        <flex-col>
          <div style="font-size: 24px">
            {{ billing.billingDetails.upgradePreview.newSubscription.package.name }}
          </div>
        </flex-col>
      </flex-row-start-center>
      <div>
        <div style="font-weight: semibold; font-size: 18px">
          {{ billing.billingDetails.upgradePreview.oldSubscription?.package.name }}
        </div>
        <table style="width: 100%; border: 1; border-collapse: collapse; margin: 10px 0 20px 0">
          <thead>
            <tr>
              <th colspan="1" style="text-align: left">{{ 'STORE.PRICE_PER_UNIT' | translate }}</th>
              <th colspan="1" style="text-align: left">{{ 'STORE.QUANTITY' | translate }}</th>
              <th colspan="1" style="text-align: right">{{ 'STORE.NET' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: left">
                {{ billing.billingDetails.upgradePreview.oldSubscription?.package.pricing.recurringFee | number: '1.2-2' }}
                {{ 'STORE.CURRENCY' | translate }}
              </td>
              <td style="text-align: left">
                {{ billing.billingDetails.upgradePreview.oldSubscription?.quantity | number: '1.2-2' }}
              </td>
              <td style="text-align: right">
                {{ billing.billingDetails.upgradePreview.oldSubscription?.net | number: '1.2-2' }}
                {{ 'STORE.CURRENCY' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- new plan and math -->
      <div>
        <div style="font-weight: semibold; font-size: 18px">
          {{ billing.billingDetails.upgradePreview.newSubscription?.package.name }}
        </div>
        <table style="width: 100%; border: 1; border-collapse: collapse; margin: 10px 0 20px 0">
          <thead>
            <tr>
              <th colspan="1" style="text-align: left">{{ 'STORE.PRICE_PER_UNIT' | translate }}</th>
              <th colspan="1" style="text-align: left">{{ 'STORE.QUANTITY' | translate }}</th>
              <th colspan="1" style="text-align: right">{{ 'STORE.NET' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr style="border-top: 1px solid #333">
              <td style="text-align: left">
                {{ billing.billingDetails.upgradePreview.newSubscription?.package.pricing.recurringFee | number: '1.2-2' }}
                {{ 'STORE.CURRENCY' | translate }}
              </td>
              <td style="text-align: left">
                {{ billing.billingDetails.upgradePreview.newSubscription?.quantity | number: '1.2-2' }}
              </td>
              <td style="text-align: right">
                {{ billing.billingDetails.upgradePreview.newSubscription?.net | number: '1.2-2' }}
                {{ 'STORE.CURRENCY' | translate }}
              </td>
            </tr>
            <tr>
              <td style="text-align: left">&nbsp;</td>
              <td style="text-align: left">
                <b>{{ 'STORE.TOTAL' | translate }}</b>
              </td>
              <td style="text-align: right; font-weight: semibold">
                {{
                  this.billing.billingDetails.upgradePreview.newSubscription?.net + this.billing.billingDetails.upgradePreview?.oldSubscription?.net
                    | number: '1.2-2'
                }}
                {{ 'STORE.CURRENCY' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  </div>
}
<!-- old, single -->
@if (shoppingCart && shoppingCart.service && shoppingCart.service.fields && shoppingCart.plan) {
  @if (!tsdUpgrade) {
    <div style="background: #eee; margin-bottom: 20px; padding: 20px">
      <!-- card header (product and plan info) -->
      <flex-row>
        <img [src]="shoppingCart.service.fields.icon.fields.file.url" color="#222" style="width: 80px; height: 80px; margin-right: 20px" />
        <flex-col>
          <div style="font-size: 24px" class="semibold">
            <span id="service-name">{{ shoppingCart.service.fields.name }}</span> -
            <span id="service-package">{{ shoppingCart.plan.fields.planVariantName }}</span>
          </div>
          @if (!isEmma()) {
            <div><span id="quantity">1 </span>License / {{ shoppingCart.plan.fields.period }}</div>
          }
          @if (isEmma()) {
            <div>
              {{ shoppingCart.plan.fields.period }}
            </div>
          }
          @if (isEmma()) {
            <div>{{ 'STORE.DATA_RETENTION_TIMEFRAME' | translate }} {{ start }} - {{ end }}</div>
          }
        </flex-col>
      </flex-row>
      <table style="width: 100%; border: 1; border-collapse: collapse; margin: 20px 0">
        <thead>
          <tr>
            <th colspan="1" style="text-align: left">{{ 'STORE.PRICE_PER_UNIT' | translate }}</th>
            <th colspan="1" style="text-align: left">{{ 'STORE.QUANTITY' | translate }}</th>
            <th colspan="1" style="text-align: right">{{ 'STORE.NET' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: left">{{ shoppingCart.plan.fields.price | number: '1.2-2' }} {{ 'STORE.CURRENCY' | translate }}</td>
            <td style="text-align: left">{{ shoppingCart.quantity }}</td>
            <td style="text-align: right">
              @if (shoppingCart.quantity) {
                {{ shoppingCart.plan.fields.price * shoppingCart.quantity | number: '1.2-2' }}
              } @else {
                no price detectable
              }
              {{ 'STORE.CURRENCY' | translate }}
            </td>
          </tr>
          <tr style="border-top: 1px solid #333">
            <td style="text-align: left">&nbsp;</td>
            <td style="text-align: left; font-weight: semibold">&nbsp;</td>
            <td style="text-align: right; font-weight: semibold">
              {{ 'STORE.TOTAL' | translate }}
              @if (shoppingCart.quantity) {
                {{ shoppingCart.plan.fields.price * shoppingCart.quantity | number: '1.2-2' }}
              } @else {
                no price detectable
              }
              {{ 'STORE.CURRENCY' | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }
}
