<flex-col-center-center style="width: 100vw; height: 100vh; background: white">
  @if (local) {
    <flex-col>
      <h1>{{ 'APP.LOCAL' | translate }}</h1>
      <flex-row style="margin-top: 10px">
        <pc-button #loginButton inputID="Log-in_3" [text]="'APP.LOG_IN' | translate" (btnClick)="login()"></pc-button>
        <div style="margin-left: 10px">
          <pc-button [routerLink]="['/register']" variant="secondary" [text]="'APP.SIGN_UP' | translate"></pc-button>
        </div>
      </flex-row>
    </flex-col>
  }
</flex-col-center-center>
