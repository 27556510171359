import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { map, Subject } from 'rxjs'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { Device, DeviceInfo, FirmwareStatusCodes, FirmwareUpdate, FirmwareUpdateIcon, FirmwareUpdateStageLabel } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { DeviceStore } from '../stores/device.store'
import { ApplicationImageResponse, ApplicationUpdate, SSEUpdateManagementMessage, UpdateStage, UpdateStatus } from './application.interfaces'
import { ApplicationService } from './application.service'
import { DeviceService } from './device.service'

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  fwList: any
  currentFwUpdate: ApplicationUpdate
  isActiveUpdate$: Subject<boolean> = new Subject()
  fwUpdateStarted$: Subject<any> = new Subject()
  fwUpdateCompleted$: Subject<{ success: boolean; message: string }> = new Subject()
  currentFWUpdateId: string
  deviceId: any
  currentUpdateStages: UpdateStage[]
  constructor(
    private deviceStore: DeviceStore,
    private deviceService: DeviceService,
    public proficloud: ProficloudService,
    private statusOverlay: PcStatusOverlayService,
    private httpBase: HttpBaseService,
    private application: ApplicationService
  ) {}

  private messageEventSource: EventSourcePolyfill
  appItems: any
  timestampStage: string

  firmwareUpdate$ = new Subject<DeviceInfo | false>()
  cancelFirmwareUpdate$ = new Subject<FirmwareUpdate | false>()
  firmwareUpdateStarted$: Subject<boolean> = new Subject()

  firmwareUpdateIcons: Record<FirmwareStatusCodes, FirmwareUpdateIcon> = {
    // not started
    '-1': false,
    // compatibility
    0: 'spinner',
    1: 'check',
    2: 'error',
    // download
    3: 'spinner',
    4: 'check',
    5: 'error',
    // download check
    6: 'spinner',
    7: 'check',
    8: 'error',
    // installation
    9: 'spinner',
    10: 'check',
    11: 'error',
    // misc errors
    12: 'error',
    13: 'error',
  }

  firmwareUpdateStages: FirmwareUpdateStageLabel[] = [
    {
      name: 'Step 01 - Checking Permissions',
      key: 'compatibility',
    },
    {
      name: 'Step 02 - Download Image',
      key: 'download',
    },
    {
      name: 'Step 03 - Checking downloaded Image',
      key: 'check_download',
    },
    {
      name: 'Step 04 - Installation',
      key: 'check_install',
    },
  ]

  getAllFirmwareUpdateHistory() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates?updateType=firmware`
    return this.proficloud.http.get(url)
  }

  loadFirmwareUpdateHistory(device: DeviceInfo) {
    console.log('deviceInfo', device)
    this.getAllFirmwareUpdateHistory().subscribe({
      next: (updates: any) => {
        //return only last 10 updates for now
        device.firmwareUpdates = updates.data.items.slice(-10).reverse()
        console.log('updateHistory', device.firmwareUpdates)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  loadAvailableDeviceFirmware(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    this.proficloud.http.get<any>(url).subscribe({
      next: (response) => {
        device.availableFirmware = response.data.upgrade_paths
        if (device.availableFirmware && device.availableFirmware.length > 0) {
          device.firmwareUpdateAllowed = true
        } else {
          device.newestFirmware = undefined
          device.firmwareUpdateAllowed = false
        }
      },
      error: (error) => {
        console.warn('single device firmware error', error)
      },
    })
  }

  listAvailableFirmware(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    return this.proficloud.http.get<any>(url).subscribe({
      next: (res) => {
        console.log('availableFirmwareList:', res.data.upgrade_paths)
        this.fwList = res.data.upgrade_paths
      },
    })
  }

  startFWUpdate(id: string, device: Device) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware` //${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${updateId}
    const payload = {
      context: 'hello!',
      data: {
        id,
      },
    }

    this.proficloud.http.post<null>(url, payload, { observe: 'response' }).subscribe({
      next: (fwResponse) => {
        const location = fwResponse.headers.get('location')
        if (!location) {
          console.warn('Location not found in response headers')
          return
        }
        const updateId = location.split('/')[location.split('/').length - 1]
        this.currentFWUpdateId = updateId
        this.firmwareUpdateStarted$.next(true)

        this.proficloud.http.get(location).subscribe((res: any) => {
          this.currentFwUpdate = res
          this.deviceId = res.data.device.id
          this.initApplicationUpdateStatus()
          this.loadUpdateStages()
          console.log(res)
        })
      },
      error: (error) => {
        this.firmwareUpdateStarted$.next(false)
      },
    })
  }

  public abortCurrentUpdate() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates/${this.currentFWUpdateId}/abort`
    let headers: HttpHeaders = new HttpHeaders(this.proficloud.getAuthHeader())
    this.proficloud.http
      .put<ApplicationImageResponse>(url, {
        headers,
      })
      .subscribe((res) => {
        this.fwUpdateCompleted$.next({ success: false, message: 'Aborted by user' })
      })
  }

  getFWUpdateName(UpdateId: string) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/firmwares/${UpdateId}`
    return this.proficloud.http.get<any>(url).pipe(
      map((response) => {
        console.log('FW Name', response)
        return response.data.version
      })
    )
  }

  loadUpdateStages() {
    this.currentUpdateStages = [
      {
        headline: 'Step 1 - Checking Compatability:',
        status: this.currentFwUpdate.compatibilityStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 2 - Downloading Image:',
        status: this.currentFwUpdate.downloadStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 3 - Verifying Downloaded Image:',
        status: this.currentFwUpdate.verifyStatus,
        timestamp: this.timestampStage,
      },
      {
        headline: 'Step 4 - Installing:',
        status: this.currentFwUpdate.installStatus,
        timestamp: this.timestampStage,
      },
    ]
  }

  populateFWList(device: DeviceInfo) {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/devices/${device.metadata.uuid}/firmware`
    return this.proficloud.http.get<any>(url)
  }

  initApplicationUpdateStatus() {
    this.currentFwUpdate.compatibilityStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentFwUpdate.downloadStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentFwUpdate.verifyStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
    this.currentFwUpdate.installStatus = {
      statusCode: -1,
      message: 'Not Started',
      progress: 0,
    }
  }

  getActiveFWUpdates() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/updates?active=true&updateType=firmware`
    return this.proficloud.http.get<any>(url)
  }

  public openStatusSSE() {
    const url = `${this.httpBase.backendUrls.updateManagementUrl}/api/v3/sse/`
    const token = localStorage.getItem('access_token')
    try {
      this.messageEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      this.messageEventSource.addEventListener('update', (evt: MessageEvent) => {
        const event: SSEUpdateManagementMessage = JSON.parse(evt.data)
        this.timestampStage = event.received_at

        let statusToUpdate: UpdateStatus | undefined

        if (event.status.includes('abort')) {
          return
        }

        // Stage
        if (event.status.includes('compat')) {
          statusToUpdate = this.currentFwUpdate.compatibilityStatus
        }

        if (event.status.includes('download')) {
          statusToUpdate = this.currentFwUpdate.downloadStatus
        }

        if (event.status.includes('verify')) {
          statusToUpdate = this.currentFwUpdate.verifyStatus
        }

        if (event.status.includes('install')) {
          statusToUpdate = this.currentFwUpdate.installStatus

          if (event.status.includes('ok')) {
            this.fwUpdateCompleted$.next({ success: true, message: 'Firmware update successful.' })
            console.log('Update FW complete')
          }
        }

        // Status
        if (!statusToUpdate) {
          console.warn('stage not recognised')
          return
        }

        if (event.status.includes('requested')) {
          statusToUpdate.statusCode = 0
          statusToUpdate.message = event.message || 'Requested'
        }

        if (event.status.includes('pending')) {
          statusToUpdate.statusCode = 1
          statusToUpdate.message = event.message || 'Pending'
        }

        if (event.status.includes('ok')) {
          statusToUpdate.statusCode = 2
          statusToUpdate.message = event.message || 'Success'
        }

        if (event.status.includes('fail')) {
          statusToUpdate.statusCode = 3
          statusToUpdate.message = event.message || 'Failed'
        }

        // Set progress if we have one
        if (event.progress) {
          statusToUpdate.progress = event.progress
        }

        this.loadUpdateStages()
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export function getFirmwareUpdateAllowState(device: DeviceInfo) {
  if (device.firmwareUpdateAllowed === false) {
    return {
      allowed: false,
      reason: 'No newer firmware versions are available for this device',
    }
  }
  // not if the device is offline
  if (!device.metadata.con_connected) {
    return { allowed: false, reason: 'Firmware update is not possible while device is offline' }
  }
  // not if there are no (new firmwares available)
  if (!device.availableFirmware || device.availableFirmware.length === 0) {
    return { allowed: false, reason: 'No suitable firmware found' }
  }
  // if none of the above conditions match, firmware update is allowed
  return { allowed: true, reason: 'device is online, firmware available, no process running' }
}
