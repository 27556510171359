import { Component, Input, OnInit } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { Observable } from 'rxjs'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { DeviceBillingService, DeviceBillingSubscription } from '../../../modules/device-management/services/device-billing.service'
import { BillingService } from '../../../services/billing/billing.service'
import { IaSubscription, SelectSubscriptionModalConfig } from '../../../services/proficloud.interfaces'

@Component({
  selector: 'app-select-subscription-modal',
  templateUrl: './select-subscription-modal.component.html',
  styleUrls: ['./select-subscription-modal.component.scss'],
})
export class SelectSubscriptionModalComponent implements OnInit {
  @Input({ required: true }) config: SelectSubscriptionModalConfig

  constructor(
    public billing: BillingService,
    public deviceBilling: DeviceBillingService,
    public proficloud: ProficloudService,
    private statusOverlay: PcStatusOverlayService
  ) {}

  availableSubscriptions: IaSubscription[] | DeviceBillingSubscription[] = []

  selectedSubscription: IaSubscription | DeviceBillingSubscription

  ngOnInit() {
    switch (this.config.serviceId) {
      case 'ia':
        this.availableSubscriptions = this.billing.impulseAnalyticsSubscriptions
        break
      case 'emma':
        // TODO wait for Clarification on EMMA for Subscriptions
        break
      case 'dmsAddonBasic':
        this.availableSubscriptions = this.deviceBilling.subscriptions
        break
      default:
        break
    }
  }

  subscriptionInfo(subscription: IaSubscription | DeviceBillingSubscription) {
    let usedSlots = 0
    let totalSlots = 0
    let endDate = ''
    switch (this.config.serviceId) {
      case 'ia':
        usedSlots = (subscription as IaSubscription).devicesUsed.length
        totalSlots = (subscription as IaSubscription).quantity
        endDate = subscription.endDate
        break
      case 'emma':
        break
      case 'dmsAddonBasic':
        usedSlots = (subscription as DeviceBillingSubscription).endpointIds.length
        totalSlots = (subscription as DeviceBillingSubscription).quantity
        endDate = (subscription as DeviceBillingSubscription).expiryDate
        break
    }
    let freeSlots = totalSlots - usedSlots
    return { usedSlots, totalSlots, freeSlots, endDate }
  }

  subscriptionAssignable(subscription: IaSubscription | DeviceBillingSubscription) {
    return this.subscriptionInfo(subscription).freeSlots > 0
  }

  updateSubscription() {
    this.billing.showSelectSubscriptionModal$.next(false)
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesBusy)
    let observableRequest$ = new Observable()
    switch (this.config.serviceId) {
      case 'ia':
        let assignedEndpoints = (this.selectedSubscription as IaSubscription).devicesUsed
        observableRequest$ = this.billing.setImpulseAssignedDevices(this.selectedSubscription.id, [...assignedEndpoints, this.config.device.endpointId])
        break
      case 'emma':
        break
      case 'dmsAddonBasic':
        let endpointIds = [this.config.device.endpointId, ...(this.selectedSubscription as DeviceBillingSubscription).endpointIds]
        observableRequest$ = this.deviceBilling.setDevicesToSubscription(this.selectedSubscription.id, endpointIds)
        break
      default:
        break
    }
    observableRequest$.subscribe({
      next: () => {
        switch (this.config.serviceId) {
          case 'dmsAddonBasic':
            this.deviceBilling.fetchDeviceBillingSubscriptions()
            break
          default:
            this.billing.fetchSubscriptions()
            break
        }
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
      },
      error: () => this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesError),
    })
  }
}
