import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { KeycloakService } from 'keycloak-angular'
import { ProficloudService } from './proficloud.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    public proficloud: ProficloudService,
    private auth: KeycloakService,
    public router: Router
  ) {}

  canActivate() {
    if (!this.auth.getKeycloakInstance().authenticated) {
      const href = window.location.href
      if (href.includes('accept-invite')) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const invitationId = urlParams.get('invitationId')
        const organizationId = urlParams.get('organizationId')
        window.localStorage.setItem('acceptedInvitation', JSON.stringify({ invitationId, organizationId }))
      }

      const pathName = window.location.pathname
      const excludeTerms = ['forgot', 'register', 'accept']
      // Put redirect URL in localstorage if we're trying to access something that isn't just the base URL.
      if (pathName.length > 1 && excludeTerms.every((term) => !pathName.includes(term))) {
        window.localStorage.setItem('requestedPath', window.location.pathname + window.location.search)
      }

      this.router.navigate(['/authenticate'])

      return false
    } else {
      return true
    }
  }
}
