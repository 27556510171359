import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export interface AnimationOutput {
  timeProgressDecimal: number
  easingProgressDecimal: number
}

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  EasingFunctions: any = {
    // no easing, no acceleration
    linear(t: number) {
      return t
    },
    // accelerating from zero velocity
    easeInQuad(t: number) {
      return t * t
    },
    // decelerating to zero velocity
    easeOutQuad(t: number) {
      return t * (2 - t)
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad(t: number) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    // accelerating from zero velocity
    easeInCubic(t: number) {
      return t * t * t
    },
    // decelerating to zero velocity
    easeOutCubic(t: number) {
      return --t * t * t + 1
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic(t: number) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    // accelerating from zero velocity
    easeInQuart(t: number) {
      return t * t * t * t
    },
    // decelerating to zero velocity
    easeOutQuart(t: number) {
      return 1 - --t * t * t * t
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart(t: number) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
    },
    // accelerating from zero velocity
    easeInQuint(t: number) {
      return t * t * t * t * t
    },
    // decelerating to zero velocity
    easeOutQuint(t: number) {
      return 1 + --t * t * t * t * t
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint(t: number) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    },
    bounce(t: number) {
      const p = 0.3
      return Math.pow(2, -10 * t) * Math.sin(((t - p / 4) * (2 * Math.PI)) / p) + 1
    },
    parabola(t: number) {
      const a = -1
      const x = t - 3
      return a * (x * x)
    },
    upDown(x: number) {
      const result = -4 * (x * x) + 4 * x
      return result
    },
  }

  animateEasing(easing: string, durationMS: number) {
    const start = new Date().getTime()
    const end = start + durationMS

    const progress$ = new Subject<AnimationOutput>()

    const step = () => {
      const now = new Date().getTime()
      let progress

      if (now > end) {
        progress = 1
      } else {
        progress = (now - start) / durationMS // percent as decimal
      }
      // now you know how much progress you've made. use this to calculate position!
      const easingProgress: number = this.EasingFunctions[easing](progress)
      progress$.next({
        timeProgressDecimal: progress,
        easingProgressDecimal: easingProgress,
      })

      // call yourself
      if (progress < 1) {
        requestAnimationFrame(step)
      } else {
        progress$.complete()
      }
    }
    step()

    return progress$
  }
}
